import dayjs, { locale } from "dayjs"
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { useLocation } from "@reach/router"
import { Image } from "./components/Image"
import { Image as ImageResolver } from "../Image/Image"
import { Mdx } from "../Mdx/Mdx"
import { Illustration } from "./components/Illustration"
import {
  ArticleEntity,
  ArticleTitle,
  ArticleTOC,
  ArticleTypes,
} from "../../entities/ArticleEntity"
import { Quote } from "./components/Quote"
import { TOC } from "./components/TOC"
import { MarketingTypes } from "../../entities/MarketingEntity"
import { Discover } from "../Marketing/Discover/Discover"
import { Newsletter } from "../Marketing/Newsletter/Newsletter"
import { Faq } from "../Marketing/Faq/Faq"
import { Title } from "./components/Title"
import { Category } from "../Category/Category"
import { H1, H2, H3, Text } from "../Mdx/components"

import ShareIcon from "@heroicons/react/20/solid/ShareIcon"
import { TwitterIcon } from "../Icons/Twitter"
import { FacebookIcon } from "../Icons/Facebook"
import { ThemeContext } from "../Theme/Theme"
import { Interactions } from "./components/Interactions"
import { Link } from "../Link/Link"
import {
  getAuthorUrl,
  getVersbUrl,
  normalizeI18nUrl,
} from "../../utils/i18nUrls"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { Button } from "../Button/Button"
import { Bookmark } from "../Bookmark/Bookmark"
import { Follow } from "../Follow/Follow"
import { Bubble } from "./components/Bubble"
import { useShare } from "../../utils/useShare"
import { slugifyForAnchors } from "../../utils/slugifyForAnchors"
import { VerbEntity, GrammarEntity } from "../../entities/VerbEntity"
import { Badge } from "../Badge/Badge"
import { useIntl } from "react-intl"
import { navigate } from "gatsby"
import { StaticPageContainer } from "../StaticPageContainer/StaticPageContainer"
import { StaticPageHeading } from "../StaticPageHeading/StaticPageHeading"
import { ListVerb } from "../ListVerb/ListVerb"
import clsx from "clsx"

const Page: React.FC<{ page: number; isActive?: boolean }> = props => {
  const intl = useIntl()

  return (
    <Link
      url={getVersbUrl(intl.locale, props.page)}
      className={clsx(
        "rounded font-display flex-shrink-0 border m-1 border-slate-200 h-10 w-10 flex items-center justify-center",
        props.isActive && "text-slate-400 bg-slate-100"
      )}
    >
      {props.page}
    </Link>
  )
}

const PreviousButton: React.FC<{ page: number }> = props => {
  const intl = useIntl()

  if (props.page === 1)
    return (
      <span
        className={clsx(
          "rounded flex-shrink-0 font-display border mt-1 mr-1 bg-slate-100 text-slate-400 border-slate-200 h-10 px-4 flex items-center justify-center"
        )}
      >
        <FormattedMessage id="verbs/pagination/previous" />
      </span>
    )

  return (
    <Link
      url={getVersbUrl(intl.locale, props.page - 1)}
      className="rounded flex-shrink-0 font-display border mt-1 mr-1 border-slate-200 h-10 px-4 flex items-center justify-center"
    >
      <FormattedMessage id="verbs/pagination/previous" />
    </Link>
  )
}

const NextButton: React.FC<{ page: number; disabled?: boolean }> = props => {
  const intl = useIntl()

  if (props.disabled)
    return (
      <span
        className={clsx(
          "rounded flex-shrink-0 font-display border mt-1 mr-1 bg-slate-100 text-slate-400 border-slate-200 h-10 px-4 flex items-center justify-center"
        )}
      >
        <FormattedMessage id="verbs/pagination/next" />
      </span>
    )

  return (
    <Link
      url={getVersbUrl(intl.locale, props.page + 1)}
      className={clsx(
        "rounded flex-shrink-0 font-display border mt-1 mr-1 border-slate-200 h-10 px-4 flex items-center justify-center"
      )}
    >
      <FormattedMessage id="verbs/pagination/next" />
    </Link>
  )
}

export const Verbs: React.FC<{
  verbs: VerbEntity[]
  page: {
    title: string
    description: string
    pagination: number
    numberOfPages: number
  }
}> = props => {
  const intl = useIntl()

  return (
    <StaticPageContainer>
      <StaticPageHeading
        categories={[]}
        title={props.page.title}
        lang={intl.locale}
        description={props.page.description}
      />

      <div className="w-full h-1 border-t mt-8 border-slate-200 "></div>

      {props.verbs.map((verb, i) => (
        <ListVerb
          verb={verb.verb}
          id={verb.id}
          definition={verb.definitions[intl.locale][0]?.definition}
          key={verb.id}
        />
      ))}

      <div className="flex flex-wrap mt-8">
        <PreviousButton page={props.page.pagination} />
        {Array.from({ length: props.page.numberOfPages }).map((e, i) => (
          <Page
            page={i + 1}
            key={i}
            isActive={props.page.pagination === i + 1}
          />
        ))}
        <NextButton
          page={props.page.pagination}
          disabled={props.page.numberOfPages === props.page.pagination}
        />
      </div>
    </StaticPageContainer>
  )
}
