import React from "react"
import { Verb } from "../components/Verb/Verb"
import { Layout } from "../components/Layout/Layout"
import { Seo } from "../components/Seo/Seo"
import { VerbEntity } from "../entities/VerbEntity"
import { MobileHeader } from "../components/MobileHeader/MobileHeader"
import { Verbs } from "../components/Verbs/Verbs"
import { useIntl } from "react-intl"
import { title } from "process"

type Props = {
  pageContext: {
    verbs: VerbEntity[]
    page: {
      pagination: number
      title: string
      description: string
      numberOfPages: number
    }
    meta: any
    aside: any
    language: string
    configuration: any
    header: any
    footer: any
    drawer: any
    cookies: any
    theme: string
    canonicals: {
      lang: string
      url: string
      isDefault: boolean
    }[]
  }
}

const VerbTemplate: React.FC<Props> = props => {
  const intl = useIntl()

  return (
    <>
      <Seo
        title={intl.formatMessage({ id: "verbs/title" })}
        description={intl.formatMessage({ id: "verbs/description" })}
        domain={props.pageContext.configuration.domain}
        lang={props.pageContext.language}
        indexable={true}
        langUrls={props.pageContext.canonicals}
      />

      <Layout
        theme={props.pageContext.theme}
        header={props.pageContext.header}
        drawer={props.pageContext.drawer}
        aside={props.pageContext.aside}
        language={props.pageContext.language}
      >
        <MobileHeader
          title={intl.formatMessage({ id: "verbs/title" })}
          description={intl.formatMessage({ id: "verbs/description" })}
        />
        <Verbs
          verbs={props.pageContext.verbs}
          page={{
            description: intl.formatMessage({ id: "verbs/description" }),
            title: intl.formatMessage({ id: "verbs/title" }),
            pagination: props.pageContext.page.pagination,
            numberOfPages: props.pageContext.page.numberOfPages,
          }}
        />
      </Layout>
    </>
  )
}

export default VerbTemplate
